import React, { createContext, useContext, useEffect, useState } from 'react'

import { isUserSubscribed } from '../../services/subscriptionPlan/getPlan'

import WalletContext from './WalletContext'

export enum LoadingState {
  IDEAL,
  LOADING,
  DATA_RECEIVED,
  ERROR,
}

const initialState = {
  loadingState: LoadingState.IDEAL,
  restrictions: {
    noOfCategories: -1,
    noOfCollections: -1,
    noOfNFT: -1,
    noOfStores: -1,
    noOfTransaction: -1,
    transactionFee: 0,
  },
}

interface restrictionsType {
  noOfCategories: number
  noOfCollections: number
  noOfNFT: number
  noOfStores: number
  noOfTransaction: number
  transactionFee: number
}

export type PlanRestrictionContextProps = {
  loadingState: LoadingState
  restrictions: restrictionsType
}

export const PlanRestrictionContext = createContext<PlanRestrictionContextProps>(initialState)

export const PlanRestrictionsProvider: React.FC<React.ReactNode> = ({ children }) => {
  const envName = process.env.NEXT_PUBLIC_ENV || 'development'
  const [restrictions, setRestrictions] = useState<restrictionsType>(initialState.restrictions)
  const [loadingState, setLoadingState] = useState<LoadingState>(initialState.loadingState)
  const { address } = useContext(WalletContext)

  useEffect(() => {
    const userSubscription = async (address: string): Promise<void> => {
      setLoadingState(LoadingState.LOADING)
      const res = await isUserSubscribed(address)
      if (res?.planDetails) {
        setRestrictions(res?.planDetails)
        setLoadingState(LoadingState.DATA_RECEIVED)
      } else {
        setLoadingState(LoadingState.ERROR)
        setRestrictions({
          noOfCategories: 0,
          noOfCollections: 0,
          noOfNFT: 0,
          noOfStores: 0,
          noOfTransaction: 0,
          transactionFee: 0,
        })
      }
    }

    if (envName === 'development') {
      setLoadingState(LoadingState.DATA_RECEIVED)
      setRestrictions({
        noOfCategories: -1,
        noOfCollections: -1,
        noOfNFT: -1,
        noOfStores: -1,
        noOfTransaction: -1,
        transactionFee: 1.75,
      })
    } else {
      userSubscription(address)
    }
  }, [address])
  return (
    <PlanRestrictionContext.Provider value={{ restrictions, loadingState }}>
      {children}
    </PlanRestrictionContext.Provider>
  )
}
