// Initialize Cloud Firestore through Firebase
import { getAnalytics } from 'firebase/analytics'
import { getApp, initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

import { firebaseConfig } from '../../src/config'

let fireApp
try {
  fireApp = getApp()
} catch (e) {
  fireApp = initializeApp(firebaseConfig)
}

const fireDB = getFirestore(fireApp)
export { fireDB, getAnalytics }
