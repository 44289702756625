import { env } from '../configs'

export const firebaseConfig = {
  apiKey: env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
  authDomain: env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: env.NEXT_PUBLIC_FIREBASE_APPID,
  measurementId: env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

// export const cognitoConfig = {
//   userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
//   clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
// }

// export const auth0Config = {
//   clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
//   domain: process.env.REACT_APP_AUTH0_DOMAIN,
// }

// export const mapConfig = process.env.REACT_APP_MAP_MAPBOX

// export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID
