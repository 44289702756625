import { CacheProvider, EmotionCache } from '@emotion/react'
import { NoSsr } from '@mui/material'
import { withAuthUser } from 'next-firebase-auth'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { SnackbarProvider } from 'notistack'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'react-quill/dist/quill.snow.css'
import { useEffect } from 'react'

import 'simplebar/src/simplebar.css'
import initAuth from '../services/auth/initAuth'
import { getAnalytics } from '../services/database/initDB'
import ThemePrimaryColor from '../src/components/ThemePrimaryColor'
import { PlanRestrictionsProvider } from '../src/contexts/PlanRestrictionsContext'
import createEmotionCache from '../src/utils/createEmotionCache'
import '../styles/nextImage.css'

import '../styles/mobileNetworkSwitch.css'
// lightbox
import 'react-image-lightbox/style.css'

const Settings = dynamic(() => import('../src/components/settings'), { ssr: false })
const Snackbar = dynamic(() => import('../services/wallet/Snackbar'), { ssr: false })
const GlobalStyles = dynamic(() => import('../src/theme/globalStyles'), { ssr: false })
const AuthGuard = dynamic(() => import('../src/guards/AuthGuard'), { ssr: false })
const ThemeConfig = dynamic(() => import('../src/theme'), { ssr: false })
const SettingsProvider = dynamic(() => import('../src/contexts/SettingsContext'), { ssr: false })
const CollapseDrawerProvider = dynamic(() => import('../src/contexts/CollapseDrawerContext'), {
  ssr: false,
})
const WalletContextProvider = dynamic(() => import('../src/contexts/WalletContextProvider'), {
  ssr: false,
})
const IsUserSubscribedProvider = dynamic(() => import('../src/contexts/IsUserSubscribedProvider'), {
  ssr: false,
})
const ProgressBar = dynamic(() => import('../src/components/ProgressBar'), {
  ssr: false,
})
const clientSideEmotionCache = createEmotionCache()

//initializing firebase auth here
initAuth()
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

const MyApp = (props: MyAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  useEffect(() => {
    getAnalytics()
  }, [])

  return (
    <WalletContextProvider>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <CacheProvider value={emotionCache}>
            <Head>
              <meta name="viewport" content="initial-scale=1, width=device-width" />
              <title></title>
            </Head>
            <ThemeConfig>
              <ThemePrimaryColor>
                <NoSsr>
                  <Settings />
                </NoSsr>
                <GlobalStyles />
                <ProgressBar />
                <SnackbarProvider maxSnack={10}>
                  <Snackbar />
                  <AuthGuard>
                    <PlanRestrictionsProvider>
                      <IsUserSubscribedProvider>
                        <Component {...pageProps} />
                      </IsUserSubscribedProvider>
                    </PlanRestrictionsProvider>
                  </AuthGuard>
                </SnackbarProvider>
              </ThemePrimaryColor>
            </ThemeConfig>
          </CacheProvider>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </WalletContextProvider>
  )
}

// MyApp.getInitialProps = async (appCtx) => {
//   const appProps = await App.getInitialProps(appCtx)
//   let host = ''
//   if (appCtx?.ctx?.req) host = appCtx.ctx.req.headers.host

//   return { ...appProps, pageProps: { host } }
// }

export default withAuthUser<MyAppProps>()(MyApp)

// export default MyApp
