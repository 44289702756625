import axios from 'axios'

import { env } from '../../configs'

export const isUserSubscribed = async (
  ownerAddress: string
): Promise<requestSubscriptionReturnType> => {
  try {
    const res = await axios.get(`${env.BE_BASE_URI}/api/user/plan/${ownerAddress}`)
    if (res.data?.success && res.data?.details) {
      return { success: true, subscribed: true, planDetails: res?.data?.details?.planDetails }
    }
    return { success: true, subscribed: false }
  } catch (e) {
    return { success: false, subscribed: false }
  }
}

interface planDetailsType {
  noOfCategories: number
  noOfCollections: number
  noOfNFT: number
  noOfStores: number
  noOfTransaction: number
  transactionFee: number
}

interface requestSubscriptionReturnType {
  success: boolean
  subscribed: boolean
  planDetails?: planDetailsType
  [key: string]: any
}
