import { envs } from '.'

export const development: envs = {
  NEXT_PUBLIC_CHAIN_ID: '0x5',
  NEXT_PUBLIC_CHAIN_ENV: 'testnet',
  NEXT_PUBLIC_RARIBLE_COLLECTION_CONTRACT_ADDRESS: {
    ETHEREUM: '0x8703360e3fc9351e116cb06deeca2eee282ad7ff',
  },
  NEXT_PUBLIC_SANITY_PROJECT_ID: 'i1b2ybyc',
  NEXT_PUBLIC_SANITY_DATASET: 'development',
  NEXT_PUBLIC_SANITY_API_VERSION: 'v2021-06-07',
  NEXT_PUBLIC_SANITY_CDN: 'true',
  NEXT_PUBLIC_FORTMATIC_API_KEY: 'pk_test_31E16D2686F19D3C',
  NEXT_PUBLIC_SEND_WYRE_API: 'https://api.testwyre.com',
  NEXT_PUBLIC_COOKIE_SECURE: 'false',
  NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID: 'G-JXLBLGKGVZ',
  METADATA_URI_PREFIX: 'https://orava.tech/api/metadata',
  NEXT_PUBLIC_FIREBASE_APPID: '1:919140966948:web:efd1989fbd51dd04c26eb1',
  NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: '919140966948',
  NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: 'orava-dev.appspot.com',
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: 'orava-dev',
  NEXT_PUBLIC_FIREBASE_DATABASE_URL:
    'https://orava-dev-default-rtdb.europe-west1.firebasedatabase.app',
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: 'orava-dev.firebaseapp.com',
  NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY: 'AIzaSyAncJ4fh8qUQQjlB6OeT1Kf7gzJzOGuyrs',
  NEXT_PUBLIC_BN_DAPP_ID: '',
  NEXT_PUBLIC_FIREBASE_FUNCTION: 'https://europe-west1-orava-dev.cloudfunctions.net/api/meta-data',
  NEXT_PUBLIC_SENTRY_ENV: 'development',
  BE_BASE_URI: 'https://europe-west1-orava-dev.cloudfunctions.net',
  NFT_CREATE_LIMIT: -1,
  NEXT_PUBLIC_HOST_URI: 'orava.tech',
  PLATFORM_FEE_ADDRESS: '',
}
