import { createContext, ReactNode, useState } from 'react'

// hooks
import {
  SettingsContextProps,
  ShopThemeSetting,
  ThemeColor,
  ThemeDirection,
  ThemeMode,
} from '../@types/settings'
import useLocalStorage from '../hooks/useLocalStorage'
// theme
import palette from '../theme/palette'

// @type

// ----------------------------------------------------------------------

const PRIMARY_COLOR = [
  // ORANGE
  {
    name: 'orange',
    lighter: '#f5b79f',
    light: '#f29572',
    main: '#E85A24',
    dark: '#de4810',
    darker: '#ad3203',
    contrastText: '#fff',
  },
  // PURPLE
  {
    name: 'purple',
    lighter: '#ed98ea',
    light: '#cf67ca',
    main: '#8a2786',
    dark: '#c720c0',
    darker: '#a1069a',
    contrastText: '#fff',
  },
  // CYAN
  {
    name: 'cyan',
    lighter: '#a3d0e3',
    light: '#72bcdb',
    main: '#29abe2',
    dark: '#1e8cba',
    darker: '#09678f',
    contrastText: '#fff',
  },
  //YELLOW OCHRE
  {
    name: 'yellow',
    lighter: '#d4b16e',
    light: '#cf9e40',
    main: '#cb8c13',
    dark: '#bf8006',
    darker: '#8a5c04',
    contrastText: '#fff',
  },
  //Dark GREEN
  {
    name: 'green',
    lighter: '#7ea3a2',
    light: '#5a9997',
    main: '#255957',
    dark: '#1d827e',
    darker: '#04706c',
    contrastText: '#fff',
  },
  //PINK
  {
    name: 'pink',
    lighter: '#deccce',
    light: '#d4babc',
    main: '#f7b8bd',
    dark: '#cc8388',
    darker: '#a85158',
    contrastText: '#fff',
  },
]

export function SetColor(themeColor: ThemeColor) {
  let color
  const ORANGE = PRIMARY_COLOR[0]
  const PURPLE = PRIMARY_COLOR[1]
  const CYAN = PRIMARY_COLOR[2]
  const YELLOW = PRIMARY_COLOR[3]
  const GREEN = PRIMARY_COLOR[4]
  const PINK = PRIMARY_COLOR[5]

  switch (themeColor) {
    case 'orange':
      color = ORANGE
      break
    case 'purple':
      color = PURPLE
      break
    case 'cyan':
      color = CYAN
      break
    case 'yellow':
      color = YELLOW
      break
    case 'green':
      color = GREEN
      break
    case 'pink':
      color = PINK
      break
    default:
      color = ORANGE
  }
  return color
}

const initialState: SettingsContextProps = {
  open: false,
  setOpen: () => null,
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColor: 'orange',
  themeStretch: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChangeMode: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChangeDirection: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChangeColor: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onToggleStretch: () => {},
  setColor: PRIMARY_COLOR[5],
  colorOption: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setInitial: () => {},
}

const SettingsContext = createContext(initialState)

type SettingsProviderProps = {
  children: ReactNode
}

function SettingsProvider({ children }: SettingsProviderProps) {
  const [open, setOpen] = useState(false)
  const [settings, setSettings] = useLocalStorage('settings', {
    themeMode: 'light',
    themeDirection: 'ltr',
    themeColor: 'orange',
    themeStretch: false,
  })

  const onChangeMode = (mode: string) => {
    setSettings({
      ...settings,
      themeMode: mode as ThemeMode,
    })
  }

  const onChangeDirection = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeDirection: (event.target as HTMLInputElement).value as ThemeDirection,
    })
  }

  const onChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      themeColor: (event.target as HTMLInputElement).value as ThemeColor,
    })
  }

  const onToggleStretch = () => {
    setSettings({
      ...settings,
      themeStretch: !settings.themeStretch,
    })
  }

  const setInitial: (newSettings: ShopThemeSetting) => void = (newSettings) => {
    if (!newSettings) return
    const { themeStretch, themeColor } = newSettings
    setSettings({
      ...settings,
      themeColor,
      themeStretch,
    })
  }

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        // Mode
        onChangeMode,
        // Direction
        onChangeDirection,
        // Color
        onChangeColor,
        setColor: SetColor(settings.themeColor),
        colorOption: PRIMARY_COLOR.map((color) => ({
          name: color.name,
          value: color.main,
        })),
        // Stretch
        onToggleStretch,
        setInitial,
        open,
        setOpen,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

export { SettingsContext, SettingsProvider }
export default SettingsProvider
