import { envs } from '.'

export const production: envs = {
  NEXT_PUBLIC_SANITY_CDN: 'true',
  NEXT_PUBLIC_SANITY_API_VERSION: 'v2021-06-07',
  NEXT_PUBLIC_SANITY_DATASET: 'production',
  NEXT_PUBLIC_SANITY_PROJECT_ID: 'q2y0vv3g',
  NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY: 'AIzaSyBcvskEGpMWjMnM-Ds2J6ZD_S36iJh-i-8',
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: 'orava-prod.firebaseapp.com',
  NEXT_PUBLIC_FIREBASE_DATABASE_URL:
    'https://orava-prod-default-rtdb.europe-west1.firebasedatabase.app',
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: 'orava-prod',
  NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: 'orava-prod.appspot.com',
  NEXT_PUBLIC_FIREBASE_APPID: '1:907532798191:web:4b9d3e3fd2989efe72b31a',
  NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID: 'G-ZTNTW0TC4B',
  NEXT_PUBLIC_FIREBASE_FUNCTION: 'https://europe-west1-orava-prod.cloudfunctions.net/api/meta-data',
  NEXT_PUBLIC_COOKIE_SECURE: 'false',
  NEXT_PUBLIC_CHAIN_ID: '0x1',
  NEXT_PUBLIC_CHAIN_ENV: 'prod',
  NEXT_PUBLIC_RARIBLE_COLLECTION_CONTRACT_ADDRESS: {
    ETHEREUM: '0x0b0e89b53d25168b16ee4514ef01b782e7659e9a',
  },
  NEXT_PUBLIC_BN_DAPP_ID: 'fb2d6a28-6827-4db5-ae13-fa2115f3457e',
  BE_BASE_URI: 'https://europe-west1-orava-prod.cloudfunctions.net',
  METADATA_URI_PREFIX: 'https://orava.xyz/api/metadata',
  NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: '907532798191',
  NEXT_PUBLIC_SENTRY_ENV: 'production',
  NEXT_PUBLIC_FORTMATIC_API_KEY: 'pk_live_2733FD2B598B49EA',
  NEXT_PUBLIC_SEND_WYRE_API: '',
  NFT_CREATE_LIMIT: 5,
  PLATFORM_FEE_ADDRESS: '',
  NEXT_PUBLIC_HOST_URI: 'orava.xyz',
}
