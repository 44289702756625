import { API as OnboardAPI, Wallet as ProviderWallet } from 'bnc-onboard/dist/src/interfaces'
import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'
import Web3 from 'web3'

export interface Wallet {
  address?: string
  chainId?: number
  openConfigure?: boolean
  setAddress: Dispatch<SetStateAction<string>>
  setChainId: Dispatch<SetStateAction<number>>
  setOpenConfigure: Dispatch<SetStateAction<boolean>>
  onboard: OnboardAPI
  setOnboard: Dispatch<SetStateAction<OnboardAPI>>
  providerWallet: ProviderWallet
  setProviderWallet: (walletProvider: ProviderWallet) => void
  notifier: any
  setNotifier: Dispatch<SetStateAction<any>>
  ballance: string
  setBallance: Dispatch<SetStateAction<string>>
}

export const useWalletContext = (): Wallet => {
  const [address, setAddress] = useState<string>(null)
  const [notifier, setNotifier] = useState<any>(null)
  const [chainId, setChainId] = useState<number>(null)
  const [openConfigure, setOpenConfigure] = useState<boolean>(false)
  const [onboard, setOnboard] = useState<OnboardAPI>(null)
  const [providerWallet, setProviderWallet] = useState<ProviderWallet>(null)
  const [ballance, setBallance] = useState('')

  return {
    address,
    setAddress,
    setChainId,
    chainId,
    openConfigure,
    setOpenConfigure,
    onboard,
    setOnboard,
    providerWallet,
    setProviderWallet,
    notifier,
    setNotifier,
    ballance,
    setBallance,
  }
}

const WalletContext = createContext<Wallet>(null)
export const useWallet = () => useContext(WalletContext)
export default WalletContext
