import { RaribleSdkEnvironment } from '@rarible/sdk/build/config/domain'

import { development } from './development'
import { production } from './production'

type ContractAddressChainType = {
  ETHEREUM: string
  POLYGON?: string
  SOLANA?: string
}
export interface envs {
  NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY: string
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: string
  NEXT_PUBLIC_FIREBASE_DATABASE_URL: string
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: string
  NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: string
  NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: string
  NEXT_PUBLIC_FIREBASE_APPID: string
  NEXT_PUBLIC_FIREBASE_FUNCTION: string
  NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID: string
  NEXT_PUBLIC_COOKIE_SECURE: string
  NEXT_PUBLIC_CHAIN_ID: string
  NEXT_PUBLIC_CHAIN_ENV: RaribleSdkEnvironment
  NEXT_PUBLIC_RARIBLE_COLLECTION_CONTRACT_ADDRESS: ContractAddressChainType
  NEXT_PUBLIC_SANITY_PROJECT_ID: string
  NEXT_PUBLIC_SANITY_DATASET: string
  NEXT_PUBLIC_SANITY_API_VERSION: string
  NEXT_PUBLIC_SANITY_CDN: string
  NEXT_PUBLIC_FORTMATIC_API_KEY: string
  NEXT_PUBLIC_SEND_WYRE_API: string
  NEXT_PUBLIC_BN_DAPP_ID: string
  NEXT_PUBLIC_SENTRY_ENV: string
  METADATA_URI_PREFIX: string
  BE_BASE_URI: string
  NFT_CREATE_LIMIT: number
  NEXT_PUBLIC_HOST_URI: string
  PLATFORM_FEE_ADDRESS: string
}

let env: envs = null

if (process.env.NEXT_PUBLIC_ENV === 'development') env = development
if (process.env.NEXT_PUBLIC_ENV === 'production') env = production

export { env }
